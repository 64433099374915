import React from 'react'
import PostCard from './PostCard'
import styled from 'styled-components'
import Anim from './Anim'

const List = styled.div`
  @media (min-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

const PostList = ({ posts }) => {
  return (
    <section>
      <Anim>
        <h1>神的尾巴</h1>
        <h2 className="site-desc">这是我的独立作品集空间</h2>
        <h3 className="site-desc-en">Enjoy code. Enjoy life.</h3>
      </Anim>

      <List>
        {posts.map(({ node }, index) => {
          return <PostCard key={index} post={node} />
        })}
      </List>
    </section>
  )
}

export default PostList
