import React from 'react'
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import { useStaticQuery, graphql } from 'gatsby'

const getPosts = graphql`
  {
    allMdx(sort: { fields: frontmatter___sort, order: DESC }) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            image
            desc
            sort
          }
        }
      }
    }
  }
`

export default () => {
  const response = useStaticQuery(getPosts)

  const posts = response.allMdx.edges

  return (
    <Layout>
      <PostList posts={posts} />
    </Layout>
  )
}
