import React from 'react'
import LazyLoad from 'react-lazyload'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Anim from './Anim'

const Card = styled.article`
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;

  &:hover {
    .card-image {
      transform: scale(1.025);
    }
  }

  a {
    text-decoration: none;
    color: var(--dark);
  }
  .card-image {
    height: 450px;
    transition: transform 0.35s;
    width: 100%;
    object-fit: cover;
    object-position: top center;
  }

  .title {
    color: #111;
  }

  .desc {
    color: #999;
  }

  @media (min-width: 600px) {
    flex: 0 0 46%;
  }
`

const Meta = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
`

const PostCard = ({ post }) => {
  const { title, image, desc } = post.frontmatter

  return (
    <Card>
      <Anim>
        <Link to={title}>
          <LazyLoad offset={200} height={450}>
            <img alt="封面" className="card-image" src={image} />
          </LazyLoad>
          <h2 className="title">{title}</h2>
          <p className="desc">{desc}</p>
          <Meta>
            <div className="btn">查看作品详情 &gt;</div>
          </Meta>
        </Link>
      </Anim>
    </Card>
  )
}

export default PostCard
